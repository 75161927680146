* {
  text-decoration: none;
}
body {
  background-color: #000;
}
.App {
  background-color: black;
  height: 100vh;
  text-align: center;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ffffff;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

.loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

/* CSS */
.container {
  margin-inline: 50px;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 12px;
}

.menu_wrapper {
  max-height: 60%;
  width: 50%;
}

.menu {
  display: flex;
  width: 100%;
  /* gap: 150px; */
  justify-content: space-between;
}

.king_text {
  font-size: xx-large;
  /* margin-bottom: 5%; */
}

.king_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
  height: 45%;
}

.king_button {
  height: 90%;
  width: 100%;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  background-color: #fff;
}

.king_button_icon {
  width: 90%;
  height: 90%;
}

.button {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 28px;
  font-weight: 500;
  height: 70px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 6px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}

.light-btn {
  background-color: #000;
  color: white;
  border: 2px solid white;
}

.button:hover {
  background: #f6f9fe;
  color: #000000;
}

.button:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button:not(:disabled) {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
    rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.button:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
    rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.button:disabled {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.logo_container {
  height: 30%;
  /* width: 90vw; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #4285f4; */
}

.logo {
  height: 100%;
}

.full_width {
  width: 90vw;
}

.button_section {
  height: 25%;
  margin-top: 30px;
}

.button-white {
  background-color: white;
  color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 25%; */
  justify-content: center;
  margin-inline: auto;
  padding-inline: 100px;
  border-radius: 12px;
  gap: 5%;
  cursor: pointer;
}

.button_text {
  font-size: x-large;
  font-weight: bold;
}

.button_icon {
  height: 50%;
  /* width: fit-content; */
}

/* lecture pge css */

.lecture_wrapper {
  max-height: 70%;
  width: 100%;
}

.className {
  position: fixed;
  top: 3%;
  background-color: #f6f9fe9e;
  color: black;
  width: 90%;
}

.text-message {
  display: flex;
  font-size: 72px;
  text-shadow: 3px 6px 14px #ffdb00d6;
  align-self: center;
  height: 40%;
  align-items: center;
  justify-content: center;
}

.message_button_container {
  bottom: 150px;
  height: 70px;
  position: fixed;
  right: 150px;
  width: 70px;
}

.message_button {
  border: none;
  height: 100px;
  width: 100px;
}

.select {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 30px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  margin: 10px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}

.select:hover {
  background: #f6f9fe;
  color: #000000;
}

.select:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.select:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.select:not(:disabled) {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.select:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
    rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.select:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.select:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
    rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.select:disabled {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.header {
  position: sticky;
  top: 0;
  text-align: center;
  /* background-color: lightgray; */
  padding-top: 20px;
  display: flex;
  background-color: black;
  padding-bottom: 10px;
}

.footer_message {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* background-color: rgb(133, 95, 95); */
  padding-bottom: 30px;
  padding-top: 10px;
  text-align: center;
  display: flex;
  background-color: black;
}

.back_btn {
  display: flex;
  height: 30px;
  width: 30px;
}

.icon {
  height: 100%;
  width: auto;
}

.title {
  color: white;
  margin-block: 0;
  width: 90%;
  font-size: xx-large;
}

/* message page css */
.message_container {
  height: 100%;
  width: 100%;
}

.text_input_message {
  width: 80%;
  height: 25px;
  padding: 15px 20px;
  border-radius: 20px;
  border: none;
  font-size: large;
}

.chat_button {
  height: 40px;
  margin: auto 10px;
  background-color: #000000;
  border: none;
}

.chat_button_img {
  height: 100%;
}

.chat_section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 75%;
  width: 100%;
  margin-top: 10px;
}

.message_title {
  width: 90%;
  color: white;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
}
.chat_section .question_container {
  margin: 0;
  gap: 30px;
}
.chat_section .question_text {
  margin: 0;
}
.question_container {
  display: flex;
  color: white;
  flex-direction: column;
  /* justify-content: space-evenly; */
  /* height: 50%; */
  /* border: 2px solid white; */
  padding-inline: 20px;
  margin-top: 20px;
  /* gap: 10px; */
}

.question_section {
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 20px;
  height: 50%;
  cursor: pointer;
  /* background-color: aqua; */
  /* margin: 10px; */
}

.question_text {
  flex: 50%;
  text-align: center;
  font-size: 30px;
  border: 2px solid white;
  border-radius: 20px;
  padding: 20px 20px;
}

.or_text {
  color: white;
  font-size: 40px;
}

.red {
  background-color: red;
  border-radius: 50%;
}

.footer_discussion {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-inline: 10%; */
  /* position: fixed; */
  width: 100%;
}

.stopwatch-time {
  font-size: xx-large;
  font-weight: 500;
}
.button_section {
  height: 25%;
  margin-top: 30px;
  background-color: white;
  color: #000000;
  padding: 10px;
  border-radius: 10px;
}

.discussion_container {
  height: 90%;
  width: 90%;
}

.student_message {
  height: 80%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name_input_container {
  top: 50px;
  /* left: 25%; */
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.name_input {
  background-color: black;
  border: 2px solid white;
  border-radius: 15px;
  height: 50px;
  color: white;
  padding-inline: 15px;
  font-size: large;
  width: 30%;
  margin-block: 2%;
}

.form_input {
  background-color: black;
  border: 2px solid white;
  border-radius: 15px;
  height: 60px;
  color: white;
  padding-inline: 15px;
  font-size: large;
  width: 90%;
  margin-block: 2%;
}

.student_questions {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;
  overflow-y: scroll;
}

.student_message_box {
  width: 97%;
  background-color: white;
  color: black;
  display: flex;
  align-items: start;
  flex-direction: row;
  gap: 5px;
  margin-block: 10px;
  border-radius: 20px;
}

.student_content {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  height: 70%;
  margin-block: 10px;
}

.message_count {
  font-size: xx-large;
  font-weight: bolder;
  text-align: center;
  margin: auto 5px;
}

.title_string {
  font-weight: bold;
  font-size: large;
}

.message_string {
  font-weight: bold;
}

.student_questions::-webkit-scrollbar {
  width: 12px;
}

.student_questions::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.student_questions::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #363636;
}

.dictaphone_header {
  display: flex;
}

.student_title {
  color: white;
  font-size: xxx-large;
  margin-top: 0;
}

.label_language {
  font-size: x-large;
}

.login_wrapper {
  display: flex;
  /* background-color: #4285f4; */
  height: 100%;
  width: 100%;
  flex-direction: row;
}

.left_section {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.right_section {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.form {
  margin-top: 5%;
  padding-top: 5%;
  width: 90%;
  height: 80%;
  border: 2px solid white;
  border-radius: 30px;
}

.form_button_section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5%;
  margin-top: 2%;
}

.left_header {
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right_image {
  height: 80%;
}

.left_image {
  height: 95%;
}

.main_logo {
  height: 55%;
}

.form_section {
  height: 80%;
}

.form_footer {
  font-size: xx-large;
  padding-top: 40px;
}

.link {
  color: #ffffffb8;
}
.form_section .form_input:focus {
  outline: 0;
}
.chat_section .message_title {
  width: 100%;
}
.message_container .footer_message {
  width: 90%;
}
.message_container .or_text {
  margin-top: 0;
  margin-bottom: 10px;
}
.message_container .message_dropdown label {
  font-size: 25px;
}
.message_container .chat_section {
  height: 65%;
}
.login_wrapper .student_title {
  font-size: 30px;
  margin-bottom: 10px;
}
.login_wrapper .form_section {
  height: auto;
}
.login_wrapper .button {
  height: 50px;
  font-size: 22px;
}
.login_wrapper .form_footer {
  padding-top: 5px;
  font-size: 30px;
}
.login_wrapper .right_section {
  justify-content: center;
}
.login_wrapper .right_section form {
  height: auto;
  padding: 50px 0px;
  margin: 0;
}
@media (max-width: 1279px) {
  .right_section form {
    height: 87%;
  }
  .login_wrapper .form_footer {
    font-size: 20px;
  }
  .chat_section .question_container {
    padding: 0;
  }
  .message_container .footer_message {
    width: 98%;
    justify-content: center;
  }
  .chat_section {
    height: 65%;
  }
  .login_wrapper .right_section form {
    padding: 20px 0px;
  }
  .login_wrapper .right_section .form_input {
    height: 45px;
  }
}
@media (max-width: 1023px) {
  .main_logo img {
    height: 80%;
  }
  .main_logo {
    height: 45%;
  }
  .main_logo .student_title {
    font-size: 40px;
  }
  .right_section .student_title {
    font-size: 30px;
  }
  .form_section .form_input {
    height: 40px;
    width: 84%;
  }
  .form_section .button {
    height: 50px;
  }
  .login_wrapper .form_footer {
    font-size: 24px;
  }
  .message_container .title {
    width: 100%;
    font-size: 25px;
  }
  .chat_section .message_title {
    font-size: 30px;
    margin-bottom: 12px;
  }
  .chat_section .question_container {
    gap: 20px;
  }
  .chat_section .question_text {
    padding: 12px;
    border-radius: 12px;
    font-size: 20px;
  }
  .message_container .or_text {
    font-size: 28px;
  }
  .chat_section {
    height: 58%;
  }
}
@media (max-width: 767px) {
  .container {
    margin-inline: 20px;
  }
  .message_container .or_text {
    margin: 15px 0px;
  }
  .message_container .footer_message {
    width: 100%;
  }
  .logo_container {
    flex-direction: column;
    height: 37%;
    justify-content: center;
  }

  .full_width {
    width: 60vw;
  }

  .student_title {
    font-size: large;
  }
  .message_container .header {
    z-index: 999;
  }
  .menu_wrapper {
    max-height: 90%;
    width: 100%;
  }

  .king_section {
    margin-top: 0px;
    height: 60%;
    gap: 40px;
    justify-content: space-between;
  }

  .king_button {
    width: 90%;
    margin-inline: 5%;
    align-items: center;
  }

  .text_input_section {
    width: 100%;
    gap: 5px;
  }

  .text-message {
    font-size: 50px;
  }

  .message_button_container {
    height: 70px;
    width: 70px;
    position: fixed;
    bottom: 110px;
    right: 50px;
  }

  h1 {
    font-size: larger;
  }

  .chat_section {
    height: 70%;
    margin-top: 0px;
    justify-content: space-evenly;
  }

  h3 {
    margin-block: 10px;
  }

  .text_input_message {
    width: 55%;
    padding: 15px 10px;
  }

  .back_btn {
    display: flex;
    height: 20px;
    width: 20px;
  }

  .chat_button {
    margin: auto 5px;
  }

  .message_title {
    font-size: 25px;
  }

  .question_container {
    display: flex;
    color: white;
    flex-direction: column;
    height: 100%;
    padding-inline: 5px;
    margin-top: 10px;
    gap: 0px;
    width: 90%;
  }

  .question_section {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }

  .title {
    font-size: larger;
  }

  .question_text {
    flex: 100%;
    width: 90%;
    border: 2px solid white;
    border-radius: 20px;
    margin-inline: auto;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .or_text {
    margin-block: 10px;
    font-size: xx-large;
  }

  .message_container .message_dropdown {
    height: 20%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .chat_section .question_container {
    gap: 15px;
    width: 100%;
  }
  .message_container .question_section {
    gap: 15px;
  }
  .footer_message {
    height: 10%;
    padding-block: 5px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .message_container .chat_section {
    height: auto;
  }
  .name_input {
    height: 40px;
    width: 80%;
    margin-block: 5%;
  }

  .discussion_container {
    height: 90%;
    width: 100%;
  }

  .logo {
    height: 55%;
  }

  .button_text {
    font-size: 16px;
  }
  .button_section {
    margin-top: 5px;
  }
  .login_wrapper {
    flex-direction: column;
    gap: 50px;
  }

  .left_section {
    width: 100%;
    height: 40%;
  }
  .main_logo .student_title {
    font-size: 18px;
    margin: 0;
  }
  .right_section form {
    margin: 0;
  }
  .form_section .button {
    height: 30px;
    font-size: 18px;
  }
  .right_section {
    width: 100%;
    height: 70%;
  }

  .form_input {
    height: 40px;
    width: 80%;
    margin-block: 2%;
  }
  .login_wrapper .right_section .form_input {
    height: 35px;
    width: 84%;
    border-radius: 10px;
    font-size: 15px;
  }
  .right_section .student_title {
    font-size: 20px;
  }
  .button_small {
    height: 40px;
  }
  .login_wrapper .form_footer {
    font-size: 15px;
  }
  .form_section {
    height: 88%;
  }

  .left_header {
    height: 40%;
    margin-top: 2%;
  }
  .right_image,
  .left_image,
  .main_logo img {
    height: 100%;
  }
  .logo_login {
    height: 145%;
  }
  .main_logo {
    height: 66%;
  }
  .small_text {
    font-size: large;
  }
}
